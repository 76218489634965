import React, { useEffect, useState } from "react";
import { FaBars, FaHome, FaClipboardList, FaBullhorn, FaChartBar, FaEnvelope } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import logo from '../../../unilorin2.jpeg';

export const StudentDashboard = () => {
  const [openToggle, setToggle] = useState(false);
  let matric = localStorage.getItem("matric")

  const onClickToggle = () => {
    setToggle(!openToggle);
  };



  function student() {
    // Define the API endpoint
    const url = 'http://localhost:3000/update-task'; // 

    const requestBody = {
      student_id: matric,
    };
  
    // Send a POST request to the server
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Task updated successfully:', data);
        
      })
      .catch((error) => {
        console.error('Error updating task:', error);
      });
  }
  


  useEffect(()=>{student()},[])


  return (
    <>
     <div className="flex h-screen bg-gray-100">
  {/* Sidebar */}
  <div
    className={`fixed top-0 left-0 z-10 h-screen w-2/3 lg:w-64 bg-white text-gray-950 p-4 flex flex-col transition-transform duration-300 ${
      openToggle ? "translate-x-0" : "-translate-x-full"
    } lg:translate-x-0`}
  >
    {/* User Info */}
    <div className="flex items-center mb-6">
      <img
        src={logo}
        alt="User Avatar"
        className="w-12 h-12 rounded-full mr-3 border-2 border-gray-500"
      />
      <div>
        <h2 className="text-xl font-semibold">{matric}</h2>
      </div>
    </div>

    {/* Dashboard Info */}
    <h2 className="text-2xl font-bold mb-4">Gns Portal 1.0</h2>
    <h2 className="text-xl font-bold mb-6">Your Dashboard</h2>

    {/* Search Bar */}
    <input
      type="text"
      placeholder="Search..."
      className="mb-4 w-full px-3 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
    />

    {/* Navigation Links */}
    <ul className="flex-1 space-y-3">
      <li>
        <Link
          onClick={onClickToggle}
          to=""
          className="flex items-center p-3 rounded-lg hover:bg-gray-700 hover:text-white transition duration-300 ease-in-out"
        >
          <FaHome className="mr-2" /> Home
        </Link>
      </li>
      <li>
        <Link
          onClick={onClickToggle}
          to="flow"
          className="flex items-center p-3 rounded-lg hover:bg-gray-700 hover:text-white transition duration-300 ease-in-out"
        >
          <FaClipboardList className="mr-2" /> Assessment
        </Link>
      </li>
      <li>
        <Link
          onClick={onClickToggle}
          to="apeal"
          className="flex items-center p-3 rounded-lg hover:bg-gray-700 hover:text-white transition duration-300 ease-in-out"
        >
          <FaBullhorn className="mr-2" /> Appeal
        </Link>
      </li>
      <li>
        <Link
          onClick={onClickToggle}
          to="send-message"
          className="flex items-center p-3 rounded-lg hover:bg-gray-700 hover:text-white transition duration-300 ease-in-out"
        >
          <FaChartBar className="mr-2" /> Check All Scores
        </Link>
      </li>
    </ul>
  </div>

  {/* Main Content */}
  <div className="flex-1 p-6 lg:ml-64 flex flex-col">
    {/* Hamburger Icon for Mobile */}
    <FaBars
      onClick={onClickToggle}
      className="lg:hidden text-gray-800 cursor-pointer mb-4"
    />

    {/* Content Outlet */}
    <div className="flex-1 overflow-auto bg-white rounded-lg shadow-lg p-4">
      <Outlet />
    </div>

  </div>
</div>

    </>
  );
};
