import React, { useState, useEffect } from "react";

export default function ViewAllStd() {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await fetch("https://researchgns.com.ng/admin/get-all-std", {
          method: "GET",
          headers: {
            "content-type": "application/json",
            ACCEPT: "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStudents(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  const headers = ['Matric No', 'Faculty/Department', 'Scores'];

  return (
    <div className="bg-white p-2 m-2 rounded-lg">
      <center>
        <h1 className="pr-4 rounded-lg pl-4 p-2 m-2 text-center text-3xl bg-white w-fit">
          All Students Score
        </h1>
      </center>
      
      <table className="table-auto w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.id}>
              <td className="border border-gray-300 px-4 py-2">{student.matric_number}</td>
              <td className="border border-gray-300 px-4 py-2">{student.falculty}</td>
              <td className="border border-gray-300 px-4 py-2">
                {student.scores && student.scores.length > 0 ? (
                  student.scores.map((score, index) => (
                    <div key={index} className="">
                        <p className="flex flex-row">
                        Score: {score}
                        </p>
                      
                        </div>
                  ))
                ) : (
                  <div>No scores available</div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
