import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "./modal";

export default function TextPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(180);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [storeAnswer, setStoreAnswer] = useState([]);
  const [score, setScore] = useState(0);
  const [on, setOn] = useState(false);
  const [submitCotrol, setControl] = useState(false);

  const locationState = location?.state?.locationState;
  const id = location?.state?.id;
  const student_id = localStorage.getItem("id");

  const handleOn = () => {
    setOn(!on);
  };

  const postAnswer = async () => {
    setControl(true);
    let correctAnswers = 0;
    storeAnswer.forEach((answer) => {
      const question = data?.find((q) => q.id === answer.questionId);
      const v1 = answer.selectedOption;
      if (question.answer === v1) {
        correctAnswers++;
      }
    });
    setScore(correctAnswers);
    try {
      const response = await fetch("https://researchgns.com.ng/answers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          answer: score,
          id: id,
          student_id: student_id,
        }),
      });

      const resdata = await response.json();
      alert(resdata.message)


      if (!response.ok) {
        navigate("/dashboard");

        return;
      }

      
      navigate("/dashboard");
      alert("successfully submited")


    } catch (error) {
      alert("Error submitting answers, please try again.");
    } finally {
      setControl(false);
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
    alert();
    postAnswer();
  }, [timeLeft]);

  async function LoadQuestion() {
    try {
      setLoading(true);
      const response = await fetch("https://researchgns.com.ng/questions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          question: locationState,
        }),
      });
      const resdata = await response.json();
      student()
      if (!response.ok) {
        return;
      }
      setData(resdata.data);
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    LoadQuestion();
  }, []);



  function student() {
    // Define the API endpoint
    const url = 'http://localhost:3000/update-task-v3'; // Replace with your actual backend URL

    const requestBody = {
      student_id: student_id,
    };
  
   
    // Send a POST request to the server
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Task updated successfully:', data);
        
      })
      .catch((error) => {
        console.error('Error updating task:', error);
      });
  }
  

  const handleAnswerChange = (questionId, selectedOption) => {
    setStoreAnswer((prevState) => {
      const updatedAnswers = prevState.filter(
        (answer) => answer.questionId !== questionId
      );
      updatedAnswers.push({ questionId, selectedOption });
      return updatedAnswers;
    });
  };

  const calculateScore = () => {
    let correctAnswers = 0;
    storeAnswer.forEach((answer) => {
      const question = data?.find((q) => q.id === answer.questionId);
      const v1 = answer.selectedOption;
      if (question.answer === v1) {
        correctAnswers++;
      }
    });
    setScore(correctAnswers);
    setOn(!on);
  };

  return (
    <div>
      {!loading && data !== null ? (
        <div>
          <center className="bg-white shadow-lg rounded-lg">
            <h1 className="text-sm font-mono p-2 m-3">
              All questions are generated based on this topic{" "}
              {location?.state?.locationState}
            </h1>
          </center>
          <div>
            <div className="text-2xl right-6 bg-gray-950 w-fit m-20 rounded-lg p-3 text-white mt-16">
              {timeLeft}
              <p className="text-red-700 text-sm">
                {timeLeft < 20 ? "You are running out of time" : null}
              </p>
             
            </div>
            <div>
              {data === null ? (
                "Please constact your LA"
              ) : (
                <div>
                  {data?.map((item) => (
                    <div key={item?.id} className="bg-white p-2 m-2 rounded-lg">
                      <h4 className="text-2xl">{item?.question}</h4>
                      <ul>
                        {item?.options?.map((option, index) => (
                          <li className="text-xl" key={index}>
                            <input
                              type="radio"
                              name={`question-${item?.id}`} // Use item.id for unique name
                              className="mr-2"
                              onChange={() =>
                                handleAnswerChange(item?.id, option)
                              } // Pass item.id to handleAnswerChange
                              checked={storeAnswer.some(
                                (answer) =>
                                  answer?.questionId === item?.id &&
                                  answer?.selectedOption === option
                              )} // Ensure correct answer selection
                            />
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <hr />
        </div>
      ) : (
        <center>
          <FaSpinner className="animate-spin text-6xl text-blue-600 " />
          <p>Loading your questions, please wait....</p>
        </center>
      )}
      <Modal
        on={on}
        controller={handleOn}
        submitCotrol={submitCotrol}
        handlesumit={postAnswer}
      />
      <center>
       <button
                className="bg-blue-800  text-white text-2xl p-3 rounded-lg"
                onClick={calculateScore}
              >
                Submit and End Test
              </button></center>
    </div>
  );
}
