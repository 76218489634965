import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";

export default function ViewStdPerson() {
  const [loading, setLoading] = useState(false);
  const [paramss, setParsmms] = useState('');
  const [std, setStd] = useState(null);

  const FechStd = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://researchgns.com.ng/admin/fetch-a-std?matric_no=${paramss}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      
      const resdata = await response.json();
      if (!response.ok) {
        alert("Check network connection");
        return;
      }
      setStd(resdata.data);
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  console.log(std)


let headers = ['SCORE', 'TIME', 'WEEK']


  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold text-center mb-6 text-gray-800">Search for a Student</h1>

      <div className="flex items-center mb-4 p-2 bg-white rounded-lg shadow-sm border border-gray-300">
        <input
          type="text"
          placeholder="Enter Matric Number"
          value={paramss}
          onChange={(e) => setParsmms(e.target.value)}
          className="p-2 w-full rounded-l-lg border-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          onClick={FechStd}
          className="bg-blue-500 text-white p-2 ml-2 rounded-r-lg flex items-center space-x-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {loading ? <FaSpinner className="animate-spin" /> : 'Search'}
        </button>
      </div>

      <div className="mt-6 text-center">
        {loading && !std ? (
          <p className="text-gray-600">Searching for a student...</p>
        ) : (
          std ? (
            <div className="p-4 bg-green-100 text-green-800 w-full rounded-lg shadow-md">
              <h2 className="text-lg font-medium">Student Information</h2>
              <p className="mt-2">Matric Number: <span className="font-bold">{std[0]?.matric_number}</span></p>
              <p>{std[0]?.email}</p>
              <p>{std[0]?.falculty}</p>
             <h1 className="text-gray-900 text-3xl">Extra Details</h1>
             <table className="min-w-full table-auto">
          <thead className="bg-white border-b  rounded-lg border-gray-300 shadow-lg">
            <tr>
              {headers.map((item, index) => (
                <th key={index} className="p-2 m-1  text-center ">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="p-1 m-1">
            {std?.map((item, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } p-2 m-1`}
              >
                <td className="text-xl text-gray-700">{item.score}</td>
                <td className="text-xl text-black">Time allow{item.time}</td>
                <td className="text-xl text-gray-900" > WEEK{item.week}</td>
              </tr>
            ))}
          </tbody>
          </table>
            </div>
          ) : (
            <p className="text-gray-600">No student found. Please try again with a valid matric number.</p>
          )
        )}
      </div>
    </div>
  );
}
