import React from 'react';
import logo from '../../src/unilorin2.jpeg';
import { useNavigate } from 'react-router-dom';
import { FaSignInAlt, FaFileAlt, FaUsers, FaBullseye, FaUser, FaOptinMonster} from "react-icons/fa";
import { Outlet } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';


function Dashboard() {

  React.useEffect(() => {
    AOS.init({ duration: 1000 }); // Animation duration in milliseconds
  }, []);
  const navigate = useNavigate()
  return (
    <div data-aos="fade-up"  className="bg-gray-100 min-h-screenp-3 m-4">

   <div  data-aos="fade-up"  className="shadow-lg bg-white  p-5 fixed left-0 right-0 top-5 rounded-lg  z-10">
    <center>
             <p data-aos="fade-down"  className='text-blue-950 text-4xl'>
              Developing And Interactive Manual for Impove GNS Content
             </p>
             <p>This is to aid student interactions</p>
             </center>
        </div>
        <Outlet /> 
    </div>
  );
}

export default Dashboard;
