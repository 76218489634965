import React, { useState } from "react";
import logo from "../../../src/unilorin2.jpeg";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa6";

export const Login = () => {
  
  const [matric, setMtatric] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    if (!matric || !password) {
      alert("Please input all fields");
      return;
    }
    try {
      const response = await fetch("https://researchgns.com.ng/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          matric_no: matric,
          password: password,
        }),
      });

      if (!response.ok) {
        alert("Login failed. Please try again.");
        return;
      }
      const data = await response.json();
      alert(data?.message);
      if(data?.status === 200){
         localStorage.setItem("token", data?.token)
         localStorage.setItem("matric",data?.matric)
         localStorage.setItem("id",data?.id)
         navigation("/dashboard")
         return;
      }

    } catch (error) {
      alert("An error occurred. Please try again.");
      console.error("Error during login:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex h-screen justify-center items-center bg-gray-200">
    {/* Login Card */}
    <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 w-11/12 sm:w-96">
      {/* Logo */}
      <img src={logo} className="h-24 mb-6" alt="Logo" />
  
      {/* Matric Input */}
      <input
        value={matric}
        onChange={(e) => setMtatric(e.target.value)}
        type="text"
        placeholder="Your Matric"
        className="p-3 mb-3 bg-gray-100 w-full rounded-md border border-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
  
      {/* Password Input */}
      <input
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="Your Password"
        className="p-3 mb-2 bg-gray-100 w-full rounded-md border border-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <i className="text-sm text-red-500 mb-4">
        Your password is your matric number in caplock
      </i>
  
      <button
        onClick={handleLogin}
        className="bg-blue-950 text-white p-3 w-full rounded-md hover:bg-blue-700 transition duration-200 flex items-center justify-center"
      >
        {loading ? (
          <FaSpinner className="animate-spin text-lg" />
        ) : (
          "Login"
        )}
      </button>
    </div>
  </div>  
  );
};
