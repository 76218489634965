import React, { useEffect, useState, useRef } from "react";
import { FaSpinner } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import TextToSpeech from "../std/voice";
import { FaCirclePlay } from "react-icons/fa6";

export const ReadOrPlay = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [read, setRead] = useState("");
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(null);
  const timerRef = useRef(null);
  const [open, setOpen] = useState(true); 

  function CutAndArrange(items) {
    let neItem = [];
    if (items) {
      let neItem = items.split(";");
      return neItem;
    }
    return neItem;
  }

  const OpenTask = () => {
    return (
      open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-scroll">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
            <h1 className="text-2xl font-bold mb-4 text-center bg-white shadow-md p-3 m-2">
              You Should be able to
            </h1>
            <p>
              {CutAndArrange(data?.[0]?.objective).map((item, index) => (
                <div>
                  <li className="text-xl p-1 capitalize text-justify">
                    {item}
                  </li>
                </div>
              ))}
            </p>
            <button
              onClick={() => setOpen(false)} // Close modal
              className="bg-red-500 text-white px-4 w-48 self-center py-2 rounded-md mt-4"
            >
              Close
            </button>
          </div>
        </div>
      )
    );
  };

  const Fetchtasks = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://researchgns.com.ng/fetch-related-task?id=${location?.state}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const datafetched = await response.json();
      setTimeLeft(datafetched?.data?.time || 0);
      setData(datafetched?.data || []);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Fetchtasks();
  }, []);

  function student(){
    const url = 'https://researchgns.com.ng/update-task-v2'; 
    const requestBody = {
      student_id:location.state,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Task updated successfully:', data);
        
      })
      .catch((error) => {
        console.error('Error updating task:', error);
      });
  }
  

  useEffect(() => {
    student();
  }, []);






  useEffect(() => {
    if (timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("timeLeft", newTime);
          if (newTime <= 0) {
            clearInterval(timerRef.current);
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timerRef.current);
    }
  }, [timeLeft]);

  const Read = (data) => {
    setRead(data);
  };

  return (
    <div>
      {loading || !data ? (
        <center>
          <FaSpinner className="animate-spin text-4xl text-blue-600" />
          <p>Please wait ....</p>
        </center>
      ) : (
        <div className="px-4 md:px-8">
          <center className="bg-white shadow-md rounded-lg p-4">
            <h3 className="text-gray-950 text-2xl md:text-3xl p-1 m-1 capitalize mt-6 mb-6">
              Week {data?.[0]?.week || "No Week Available"}
            </h3>
            <p>{data?.[0]?.tags}</p>
            <h2 className="text-xl md:text-2xl text-gray-950 p-1 m-1 capitalize mt-6 mb-6">
              {data?.[0]?.post || "No Post Available"}
            </h2>
          </center>
          <div className="flex flex-col md:flex-row gap-6 items-start justify-between mt-4">
            {/* Left Column */}
            <div className="max-h-screen overflow-y-scroll w-full md:w-1/2">
              {Array.isArray(data) &&
                data.map((item, index) => (
                  <div
                    className="shadow-lg bg-gray-200 mt-6 rounded-lg p-4"
                    key={index}
                  >
                    <button onClick={() => Read(item?.explains)}>
                      <center>
                        <h1 className="text-lg md:text-2xl text-gray-950 pt-3 pb-3">
                          {item.sub_title}
                        </h1>
                      </center>
                      <p className="text-justify p-2">{item.explains}</p>
                    </button>
                  </div>
                ))}
            </div>

            <div className="w-full h-auto mt-4">
              <div className="rounded-lg shadow-lg">
                {data?.[0]?.video ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${
                      data?.[0]?.video.split("v=")[1] ||
                      data?.[0]?.video.split("youtu.be/")[1]
                    }`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="YouTube Video"
                    className="w-full h-64 md:h-96"
                  />
                ) : (
                  <p className="text-center text-gray-500">
                    No video available for this task.
                  </p>
                )}
              </div>

              <TextToSpeech text={read} />
            </div>

            {loading && (
              <FaSpinner className="animate-spin text-4xl text-blue-600" />
            )}

            {/* Right Column */}
            <div className="w-full md:w-1/2">
              <center>
                <textarea
                  disabled
                  className="p-4 bg-white shadow-lg border border-gray-800 rounded-lg w-full text-base md:text-xl h-40 md:h-60"
                  value={data?.[0]?.summary}
                ></textarea>
                <TextToSpeech text={`here is the  ${data?.[0]?.summary}`} />
              </center>
            </div>
          </div>

          {/* Instructions Section */}
          <div className="bg-white shadow-lg p-4 mt-8 rounded-lg">
            <h1 className="text-lg md:text-2xl font-bold">Instructions</h1>
            <ul className="text-left p-4 text-gray-600 list-disc text-base md:text-lg">
              <li>Do not refresh your page while taking the test.</li>
              <li>20 questions to be answered.</li>
              <li>
                Refreshing or navigation through a screen will result in failure
                (0 marks recorded).
              </li>
              <li>
                If you encounter an error, use the appeal button on your
                dashboard to lodge a complaint.
              </li>
            </ul>
            <button
              onClick={() => {
                if (timeLeft > 0) {
                  alert(`Spend ${timeLeft} seconds on this topic.`);
                  return;
                }
                navigate("/test", {
                  state: {
                    locationState: data?.[0].post,
                    id: location?.state,
                  },
                });
              }}
              className="bg-blue-900 p-3 mt-4 rounded-lg text-white w-full text-lg md:text-2xl"
            >
              {timeLeft > 0 ? `Time Left: ${timeLeft}` : "Take Your Test"}
            </button>
          </div>
        </div>
      )}
      <OpenTask />
    </div>
  );
};
