import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import prof1 from '../profone (1).jpg'
import prof2 from '../profone (2).jpg'
import prof3 from '../profone (3).jpg'
import prof4 from '../profone (4).jpg'
import prof5 from '../profone (5).jpg'
import prof9 from '../prof9.jpeg'
import prof8 from '../prof8.jpg'
import 'aos/dist/aos.css';
import AOS from 'aos';
// Importing images for each research group (example)
import Prof from '../prof.jpeg'

import logo from "../../src/university.jpg";
import "./landing.css";

export const  LandingPage = () => {
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    AOS.init({ duration: 1000 }); // Animation duration in milliseconds
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(true);
    }, 300); // Start fade-in after 300ms
    return () => clearTimeout(timer);
  }, []);
  
  const researchGroups = [
    { title: "Prof L.A YAHAYA", image: prof9},
    { title: "Prof. Medinat F. Salman", image: prof8 },
    { title: "Dr. M. B. Bello  (Virtual Attendee)", image: prof4 },
    { title: "Prof. Olufunmilola Dominic", image: prof3 },
    { title: "Prof. N.A. Adedokun", image: Prof },
    { title: "Prof. M. Ogunjimi", image: prof5 },
    { title: "Dr. A. A. Atolagbe", image: prof2 },
    { title: "Dr. Foluke Bolu-Steve", image: prof1 },
    { title: "Dr. D. J. Kayode", image: Prof },
    { title: "Dr. Hafsat I. Alabi", image: Prof },
    { title: "Dr. M.S. Abdullah", image: Prof },
  ]
  return (
    <div className="mt-10 h-screen relative rounded-lg ml-4">
    {/* Background */}
    <div
      className="absolute inset-0"
      style={{
        backgroundImage: `url(${logo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: 0.7, // Adjust this value for opacity
      }}
    />
  
    {/* Foreground Content */}
    <div className="relative z-10 flex flex-col h-full animate-fade-in">
      {/* Welcome Message */}
      <center>
        <div  data-aos="fade-up" className="mt-32 bg-white w-fit p-6 rounded-lg shadow-lg">
          <h3 data-aos="fade-up" className="text-blue-900 text-2xl font-light m-1 p-1">
            Welcome GNS RESEARCH WORK
          </h3>
          <p data-aos="fade-up" className="text-blue-900 font-semibold text-lg">
            Improving Assessment Quality: This is to aid and improve GNS practice
            in the university.
          </p>
        </div>

        
      <p data-aos="fade-up" className="p-2 m-1 bg-white w-fit rounded-md  text-blue-950"> if you are here via a link, testing or part of the research kindly click bellow</p>
        
        <div className="flex flex-direction justify-around self-center w-1/2">
        <button
            onClick={() => navigate("/register")}
            className="shadow-lg mt-4 py-2 px-6 rounded-xl text-white bg-blue-600 hover:bg-blue-700 text-xl transition duration-200"
          >
       Join research
          </button>

          <button
            onClick={() => navigate("/login")}
            className="shadow-lg mt-4 py-2 px-6 rounded-xl text-white bg-blue-600 hover:bg-blue-700 text-xl transition duration-200"
          >
       Continue
          </button>
</div>

      </center>
  
      {/* Research Groups Title */}
      <center>
        <div data-aos="fade-up" className="text-blue-950 p-3 mt-10 bg-white text-3xl font-semibold w-fit rounded-lg shadow-md">
          LIST OF RESEARCHERS
        </div>
      </center>
  
      {/* Grid Layout for Research Groups */}
      <div data-aos="fade-up" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 p-6 m-4 mb-44">
        {researchGroups.map((item, index) => (
          <div data-aos="fade-up"
            key={index}
            className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition duration-200"
          >
            <img data-aos="fade-up"
              src={item.image}
              alt={item.title}
              className="w-20 h-20 rounded-full mx-auto"
            />
            <h2 data-aos="fade-up" className="text-center mt-4 mb-20 text-gray-700 font-medium text-lg">
              {item.title}
            </h2>
          </div>
        ))}
      </div>
    </div>
  </div>
  
  );
};
